import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";
import Loading from "./components/main-loader";
import ErrorBoundary from "./components/error-boundary";
import { ZIndexProvider } from "./providers/z-index";

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <ZIndexProvider>
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.element />}
                  />
                ))}
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </ZIndexProvider>
      </div>
    </Router>
  );
};

export default App;
