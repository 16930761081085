import React, { createContext, useState, useContext } from "react";

interface ZIndexContextProps {
  nextZIndex: number;
  incrementZIndex: () => void;
}

const ZIndexContext = createContext<ZIndexContextProps | undefined>(undefined);

export const ZIndexProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [nextZIndex, setNextZIndex] = useState(1);

  const incrementZIndex = () => {
    setNextZIndex((prev) => prev + 1);
  };

  return (
    <ZIndexContext.Provider value={{ nextZIndex, incrementZIndex }}>
      {children}
    </ZIndexContext.Provider>
  );
};

export const useZIndex = () => {
  const context = useContext(ZIndexContext);
  if (context === undefined) {
    throw new Error("useZIndex must be used within a ZIndexProvider");
  }
  return context;
};
